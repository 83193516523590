import React, { Component } from "react";
import "./css/Footer.css"

export default class Footer extends Component {
    render() {
        return(

        <div class="container-fluid footer mt-5 text-white">
  <div class="container p-5">
    <div class="row">
      <div class="col-lg-4 col-md-12">  
        <h6>
        DayOneTechSolutions</h6>
        <p>Android Software for Lead Generation.</p>
      </div>
      <hr id="mobile_hr"/>
      <div class="col-lg-4 col-md-12">
        <h6>Company</h6>
        <p><a href="/about" class="footer-link">About</a></p>
        <p><a href="mailto:joshua@dayonetechsolutions.com" class="footer-link">Jobs</a></p>
        <p><a href="https://www.instagram.com/dayonetechsolutions" class="footer-link">Instagram</a></p>
      </div>
      <hr id="mobile_hr"/>
      <div class="col-lg-4 col-md-12">
        <h6>Resources</h6>
        <p><a href="mailto:joshua@dayonetechsolutions.com" class="footer-link">Contact</a></p>
        <p><a href="mailto:joshua@dayonetechsolutions.com" class="footer-link">Support</a></p>
        <p><a href="/terms" class="footer-link">Privacy & terms</a></p>
        <p><a href="/policy" class="footer-link">Policy</a></p>
      </div>
      <hr id="mobile_hr"/>
    </div>

  <div class="container p-2">
    <p class="text-center">© 2022 DayOneTechSolutions. All Rights Reserved.</p>
  </div>
</div>
</div>

        );
    }
}
