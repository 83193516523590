import React, { Component } from "react";

import NavBar from "./NavBar";
import Pricing from "./Pages/Pricing";
import Footer from "./Footer";
import Main from "./Pages/Main"

import About from "./Pages/About";
import Terms from "./Pages/Terms";
import Policy from "./Pages/Policy";

import { BrowserRouter, Route, Routes} from "react-router-dom";

export default class App extends Component {
    render() {
        return (
          <BrowserRouter>
            <React.Fragment>
		          <NavBar/>
                <Routes>
                  <Route path="/" element={<Main/>}/>
                  <Route path="/about" element={<About/>}/>
                  <Route path="/terms" element={<Terms/>}/>
                  <Route path="/policy" element={<Policy/>}/>
                  <Route path="/pricing" element={<Pricing/>}/>
                  <Route path="*" element={<Main/>}/>
                </Routes>
                
              <Footer/>
            </React.Fragment>
          </BrowserRouter>
        );
    }
}
