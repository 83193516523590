import React, { Component } from "react";

import CTA from "./Fragments/CTAFragment";
import PricingFragment from "./Fragments/PricingFragment";
import AboutFragment from "./Fragments/AboutFragment";
import TestimonialFragment from "./Fragments/TestimonialFragment";

import { BrowserRouter, Route, Routes} from "react-router-dom";

export default class Main extends Component {
    render() {
        return (
            <React.Fragment>
                <CTA/>
                
                <AboutFragment/>
                <TestimonialFragment/>
                <PricingFragment/>
                
            </React.Fragment>
        );
    }
}
