import { Component } from "react";

import "./css/Testimonial.css";

import TestimonialShard from "./Shards/TestmonialShard";

export default class Testimonial extends Component{
    state = {  };
    render() {
        return(
            <div class="my-5">
            <div class="container p-5">
               <h1>What do our clients say?</h1>
             <div class="row p-5">
                <TestimonialShard personText="It was fast and easy. Wow!" rating="5" personTitle="Real Estate Entrepreneur" personName="Mike Nguyen" personImg=""/>
             </div>
           </div>
           </div>
        );
    }
}