import React, { Component } from "react";

import "./css/NavBar.css";

export default class NavBar extends Component {
    render() {
        return(
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-style">
                <div className="container-fluid">
                <a className="navbar-brand" href="/">DayOneTechSolutions</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="/">Services</a>
                    </li>
                    <li className="nav-item dropdown">        
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Start Now
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href="pricing">Membership</a></li>
                            <li><a className="dropdown-item" href="https://buy.stripe.com/cN2dSAaUf4iJaha4gl">Add a Phone</a></li>
                        </ul>
                        </li>
                    </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

/*
<li className="nav-item dropdown">
<a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    Dropdown
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
    <li><a className="dropdown-item" href="/#">Action</a></li>
    <li><a className="dropdown-item" href="/#">Another action</a></li>
    <li><hr className="dropdown-divider"/></li>
    <li><a className="dropdown-item" href="/#">Something else here</a></li>
</ul>
</li>
*/
