import React, { Component } from "react";

import "./css/Pricing.css";

export default class Pricing extends Component{
    state = {  };
    render() {
        return(
            <React.Fragment>
            <div className="container-fluid">
                <div className="container p-5">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 mb-4">
                            <div className="card card1 h-100">
                                <div className="card-body">
                                
                                <h5 className="card-title">Regular</h5>
                                <small className='text-muted'>Individual</small>
                                <br/><br/>
                                <span className="h2">$100</span>/month
                                <br/><br/>
                                <div className="d-grid my-3">
	                                <a href="https://buy.stripe.com/cN28yggezbLbdtm28a" class="btn btn-outline-dark btn-block" role="button">Select</a>
                                </div>
                                <ul>
                                    <li>1000 SMS/day</li>
                                    <li>SMS Reader/Sender</li>
                                    <li>1 Phone Line</li>
                                    <li>Support</li>
                                </ul>
                                </div>

                                
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mb-4">
                            <div className="card card2 h-100">
                                <div className="card-body">
                                
                                <h5 className="card-title">Business</h5>
                                <small className='text-muted'>Small Business</small>
                                <br/><br/>
                                <span className="h2">$250</span>/month
                                <br/><br/>
                                <div className="d-grid my-3">
                                    <a href="https://buy.stripe.com/28o5m45zVdTj1KE8wx" class="btn btn-outline-dark btn-block" role="button">Select</a>
                                </div>
                                <ul>
                                    <li>2500 SMS/day</li>
                                    <li>Smart Dialer, SMS Reader/Sender</li>
                                    <li>1 Phone Line</li>
                                    <li>Priority Support</li>
                                </ul>
                                </div>

                                
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mb-4">
                            <div className="card card3 h-100">
                                <div className="card-body">
                                
                                <h5 className="card-title">Enterprise</h5>
                                <small className='text-muted'>Large Company</small>
                                <br/><br/>
                                <span className="h2">$500</span>/month
                                <br/><br/>
                                <div className="d-grid my-3">
                                    <a href="https://buy.stripe.com/6oE29SbYj2aBdtm3cc" class="btn btn-outline-dark btn-block" role="button">Select</a>
                                </div>
                                <ul>
                                    <li>5000 SMS/day</li>
                                    <li>Smart Dialer, SMS Reader/Sender</li>
                                    <li>2 Phone Lines</li>
                                    <li>Top Priority Support</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
            </React.Fragment>
        );
    }
}
