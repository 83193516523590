import { Component } from "react";

import "./css/About.css";

export default class About extends Component{
    state = {  };
    render() {
        return(
		 <div className="container-fluid py-5 bg-light">
		    <div className="container p-5">
		      <div className="row">
			<div className="col-lg-6 col-md-12">
				
			<img src="https://images.pexels.com/photos/193003/pexels-photo-193003.jpeg?cs=srgb&dl=pexels-torsten-dettlaff-193003.jpg&fm=jpg" id="img_desktop" className="rounded-circle" alt="Responsive image" width="500px" height="450px"></img>

			</div>
			<div className="col-lg-6 col-md-12 my-auto">
			  <h2 className="font-weight-bold text-center">SMS/Calling on the Android</h2>
			  <h5 className="text-muted text-center lead">On-the-Go Android tools for SMS and Dialing. Convienence made possible	</h5>
			  <ul className="text-right">
			      <li>SMS Platform & Manager</li>
			      <li>Smart Dialer</li>
			      <li>Great Support Team</li>
			  </ul>
			</div>
		      </div>
		    </div>
		  </div>	
          );
    }
}
