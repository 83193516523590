import React, { Component } from "react";

import "./css/Pricing.css";
import PricingFragment from "./Fragments/PricingFragment";

export default class Pricing extends Component{
    state = {  };
    render() {
        return(
            <React.Fragment>
            <div id="text_desktop" className="text-center p-5">
                <h1>Lead Generation Made Convenient</h1>
		        <h4>The Only Business Platform for the Android Phone</h4>
            </div>
            <PricingFragment/>
            
            </React.Fragment>
        );
    }
}
