import { Component } from "react";

import "./css/CTA.css";

export default class Main extends Component{
    state = {  };
    render() {
        return(
            <div className="container py-5 text-right text-dark">
                <div className="row p-5">
                <div className="col-lg-6 col-md-12 my-auto">
                    <h1 className="display-3">On the Phone Lead Generation</h1>
                    <h5 className="purple">Android software for your business.</h5>
                    <br/>
                    <a className="btn btn-dark btn-lg" href="pricing" role="button">Start now</a><br id="br_mobile"/> <br id="br_mobile"/><a className="btn btn-dark btn-lg" href="https://buy.stripe.com/cN2dSAaUf4iJaha4gl" role="button">Add Another Line</a>
                </div>
                <div className="col-lg-6 col-md-12">
                <img src="https://images.pexels.com/photos/336948/pexels-photo-336948.jpeg?cs=srgb&dl=pexels-terje-sollie-336948.jpg&fm=jpg" class="rounded-circle" alt="Responsive image" width="500px" height="450px" id="img_desktop"/>
                </div>
                </div>
            </div>
            
        );
    }
}
